<template>
  <div>
    <div class="d-flex align-items-center justify-content-end">
      <b-button
        variant="gradient-primary"
        @click="submit"
      >
        <i
          class="fad fa-cloud-download-alt"
          style="color: #fff"
        />
        บันทึกข้อมูล
      </b-button>
    </div>
    <b-row class="match-height mt-1">
      <b-col md="6">
        <b-card>
          <h3>
            <i class="fad fa-university" /> จัดการเลขบัญชีธนาคารลูกค้าโอนเข้า
          </h3>
          <b-tabs pills>
            <b-tab
              title="บัญชีธนาคารที่ 1"
              active
            >
              <b-form-group label="ชื่อธนาคาร">
                <v-select
                  v-model="AgentData.dip_bank_code"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="banklist"
                  :reduce="name => name.bankcode"
                />
              </b-form-group>
              <b-form-group
                label="เลขที่บัญชี"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label1"
                  v-model="AgentData.dip_bank_accno"
                  placeholder="เลขที่บัญชี"
                />
              </b-form-group>

              <b-form-group
                label="ชื่อบัญชี"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="AgentData.dip_bank_accname"
                  placeholder="ชื่อบัญชี"
                />
              </b-form-group>
              <b-form-group
                label="internet banking username"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="AgentData.dip_bank_username"
                  placeholder="username"
                />
              </b-form-group>
              <b-form-group
                label="internet banking password"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="AgentData.dip_bank_password"
                  placeholder="password"
                />
              </b-form-group>
              <b-form-group
                label="เบอร์โทร ที่ใช้รับ SMS"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="AgentData.dip_bank_sms"
                  placeholder="เบอร์โทร"
                />
              </b-form-group>
            </b-tab>
            <b-tab title="บัญชีธนาคารที่ 2">
              <div>
                <b-form-group label="ชื่อธนาคาร">
                  <v-select
                    v-model="AgentData.dip_bank2_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="AgentData.dip_bank2_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.dip_bank2_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking username"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.dip_bank2_username"
                    placeholder="username"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking password"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.dip_bank2_password"
                    placeholder="password"
                  />
                </b-form-group>
                <b-form-group
                  label="เบอร์โทร ที่ใช้รับ SMS"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.dip_bank2_sms"
                    placeholder="เบอร์โทร"
                  />
                </b-form-group></div></b-tab>
          </b-tabs>

          <hr>
          <label for="lable">ยอดฝากจากธนาคารอื่น</label>
          <b-form-checkbox
            v-model="AgentData.dip_trigger"
            :checked="AgentData.dip_trigger"
            class="custom-control-info"
            name="check-button"
            switch
            value="statment"
            unchecked-value="SMS"
          />
          {{ AgentData.dip_trigger }}
          <!-- <div class="can-toggle">
            <input
              id="a"
              v-model="AgentData.dip_trigger"
              type="checkbox"
              value="statment"
              name="check-button"
            >

            <label for="a">
              <div
                class="can-toggle__switch"
                data-checked="statment"
                data-unchecked="SMS"
              />
            </label>
          </div> -->
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3>
            <i class="fad fa-university" />
            จัดการเลขบัญชีธนาคารโอนออกให้ลูกค้า
          </h3>

          <b-tabs pills>
            <b-tab
              title="บัญชีธนาคารที่ 1"
              active
            >
              <div>
                <b-form-group label="ชื่อธนาคาร">
                  <v-select
                    v-model="AgentData.wit_bank1_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="AgentData.wit_bank1_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank1_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking username"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank1_username"
                    placeholder="username"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking password"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank1_password"
                    placeholder="password"
                  />
                </b-form-group>
                <b-form-group
                  label="เบอร์โทร ที่ใช้รับ SMS"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank1_sms"
                    placeholder="เบอร์โทร"
                  />
                </b-form-group>
              </div>
            </b-tab>
            <b-tab title="บัญชีธนาคารที่ 2">
              <div>
                <b-form-group label="ชื่อธนาคาร">
                  <v-select
                    v-model="AgentData.wit_bank2_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="AgentData.wit_bank2_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank2_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking username"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank2_username"
                    placeholder="username"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking password"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank2_password"
                    placeholder="password"
                  />
                </b-form-group>
                <b-form-group
                  label="เบอร์โทร ที่ใช้รับ SMS"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank2_sms"
                    placeholder="เบอร์โทร"
                  />
                </b-form-group>
              </div>
            </b-tab>
            <b-tab title="บัญชีธนาคารที่ 3">
              <div>
                <b-form-group label="ชื่อธนาคาร">
                  <v-select
                    v-model="AgentData.wit_bank3_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="AgentData.wit_bank3_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank3_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking username"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank3_username"
                    placeholder="username"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking password"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank3_password"
                    placeholder="password"
                  />
                </b-form-group>
                <b-form-group
                  label="เบอร์โทร ที่ใช้รับ SMS"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="AgentData.wit_bank3_sms"
                    placeholder="เบอร์โทร"
                  />
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

      <!-- <b-col md="6">
        <b-card>
          <h3><i class="fad fa-gifts" /> จำนวนเทินที่ลูกค้าไม่รับโปร</h3>
          <b-form-group
            label="จำนวนเทิน"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="AgentData.turn_nopro"
              type="number"
              placeholder="จำนวนเทิน"
            />
          </b-form-group>
        </b-card>
      </b-col> -->
      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-wallet" /> จัดการ truemoney ขาเข้า</h3>
          <b-form-group
            label="เบอร์ทรูมันนี่"
            label-for="customDelimiter"
          >
            <cleave
              id="customDelimiter"
              v-model="AgentData.truemoney_acc"
              class="form-control"
              :raw="false"
              :options="options.customDelimiter"
              placeholder=""
            />
          </b-form-group>
          <b-form-group
            label="ชื่อบัญชี"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="AgentData.truemoney_name"
              placeholder="ชื่อบัญชี"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fab fa-ethereum" /> ยอดถอนต่อครั้ง</h3>
          <b-form-group
            label="ถอนเครดิตต่ำสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="AgentData.withdraw_min"
              type="number"
              placeholder="ถอนเครดิตต่ำสุด"
            />
          </b-form-group>
          <b-form-group
            label="ถอนเครดิตสูงสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="AgentData.withdraw_max"
              type="number"
              placeholder="ถอนเครดิตสูงสุด"
            />
          </b-form-group>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card>
          <h3>
            <i class="fad fa-donate" /> ยอดถอนเครดิตสูงสุด
            <small>(โดยไม่ต้อง approve)</small>
          </h3>
          <b-form-group
            label="ถอนเครดิตสูงสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="AgentData.withdraw_approve"
              type="number"
              placeholder="ถอนเครดิตสูงสุด"
            />
          </b-form-group>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card>
          <h3>
            <i class="fad fa-donate" /> ตั้งค่าส่วนลด
            <small>(%)</small>
          </h3>
          <b-form-group
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="AgentData.discount"
              type="number"
              placeholder="ถอนเครดิตสูงสุด"
            />
          </b-form-group>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card>
          <h3>
            <i class="fas fa-cogs" /> ตั้งค่าเพิ่มเติม
            <small>(setting other...)</small>
          </h3>
          <div class="row">
            <div class="col-md-3">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="AgentData.name"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group
                label="Prefix"
                label-for="prefix"
              >
                <b-form-input
                  id="prefix"
                  v-model="AgentData.prefix"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group
                label="Site"
                label-for="site"
              >
                <b-form-input
                  id="site"
                  v-model="AgentData.site"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group
                label="Turn no promotion"
                label-for="turn_nopro"
              >
                <b-form-input
                  id="turn_nopro"
                  v-model="AgentData.turn_nopro"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Line Token Diposit"
                label-for="linetoken_dip"
              >
                <b-form-input
                  id="linetoken_dip"
                  v-model="AgentData.linetoken_dip"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Line Token Withdraw"
                label-for="linetoken_wit"
              >
                <b-form-input
                  id="linetoken_wit"
                  v-model="AgentData.linetoken_wit"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Line Token Captcha"
                label-for="linetoken_captcha"
              >
                <b-form-input
                  id="linetoken_captcha"
                  v-model="AgentData.linetoken_captcha"
                  type="text"
                  placeholder=""
                />
              </b-form-group>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card no-body>
          <h3 class="p-2">
            <i class="fas fa-cogs" /> ตั้งค่าเพิ่มเติม
            <small>(setting other...)</small>
          </h3>

          <b-table
            small
            striped
            hover
            responsive
            class="position-relative items-center"
            :items="items"
            :fields="fields"
          >
            <template #cell(index)="data">
              {{ perPage * (currentPage - 1) + (data.index + 1) }}
            </template>

            <template #cell(subhead)="data">
              <div
                v-if="data.item.Subhead.length > 0"
                class="text-nowrap"
              >
                <b-button
                  size="sm"
                  :variant="data.detailsShowing ? 'secondary' : 'warning'"
                  @click="data.toggleDetails"
                >
                  {{ data.detailsShowing ? 'ซ่อน' : 'เพิ่มเติม' }}
                </b-button>
              </div>
            </template>

            <template #cell(ag_status)="data">
              <div class="text-nowrap">
                <b-form-checkbox
                  v-model="data.item.ag_status"
                  :checked="data.item.ag_status"
                  class="custom-control-info"
                  name="check-button"
                  switch
                  value="1"
                  unchecked-value="0"
                >
                  <span class="switch-icon-left">
                    เปิด
                  </span>
                  <span class="switch-icon-right">
                    ปิด
                  </span>
                </b-form-checkbox>
              </div>
            </template>

            <template #row-details="row">
              <b-card>
                <b-table
                  responsive="sm"
                  :items="row.item.Subhead"
                  :fields="SubFields"
                >
                  <template #cell(index)="data">
                    {{ perPage * (currentPage - 1) + (data.index + 1) }}
                  </template>
                  <template #cell(ag_status)="data">
                    <div class="text-nowrap">
                      <b-form-checkbox
                        v-model="data.item.ag_status"
                        :checked="data.item.ag_status"
                        class="custom-control-info"
                        name="check-button"
                        switch
                        value="1"
                        unchecked-value="0"
                      >
                        <span class="switch-icon-left">
                          เปิด
                        </span>
                        <span class="switch-icon-right">
                          ปิด
                        </span>
                      </b-form-checkbox>
                    </div>
                  </template>
                </b-table>
              </b-card>
            </template>
          </b-table>

          <b-card-body
            class="p-1 d-flex justify-content-between flex-wrap pt-0"
          >
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTab,
  BTabs,
  BTable,
  BPagination,
  // BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    vSelect,
    BFormInput,
    BButton,
    BTab,
    BTabs,
    BTable,
    BPagination,
    // BFormSelect,
    BFormCheckbox,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      AgID: this.$route.params.id,
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'name', label: 'หวย' },
        { key: 'Subhead', label: 'ประเภทหวย' },
        { key: 'ag_status', label: 'สถานะ' },
      ],
      SubFields: [
        { key: 'index', label: 'no.' },
        { key: 'name', label: 'หวย' },
        { key: 'ag_status', label: 'สถานะ' },
      ],
      items: [],
      DefaultItem: [],
      banklist: [],
      banklist2: [{ bankcode: '004', name: 'ธนาคารกสิกรไทย' }],
      AgentData: {
        prefix: null,
        name: null,
        site: null,
        discount: null,
        turn_nopro: null,
        withdraw_min: null,
        withdraw_max: null,
        withdraw_approve: null,
        truemoney_acc: null,
        truemoney_name: null,
        dip_trigger: 'statment',
        dip_bank_code: null,
        dip_bank_accno: null,
        dip_bank_accname: null,
        dip_bank_username: null,
        dip_bank_password: null,
        dip_bank_sms: null,
        dip_bank2_code: null,
        dip_bank2_accno: null,
        dip_bank2_accname: null,
        dip_bank2_username: null,
        dip_bank2_password: null,
        dip_bank2_sms: null,
        wit_bank1_code: null,
        wit_bank1_accno: null,
        wit_bank1_accname: null,
        wit_bank1_username: null,
        wit_bank1_password: null,
        wit_bank1_sms: null,
        wit_bank2_code: null,
        wit_bank2_accno: null,
        wit_bank2_accname: null,
        wit_bank2_username: null,
        wit_bank2_password: null,
        wit_bank2_sms: null,
        wit_bank3_code: null,
        wit_bank3_accno: null,
        wit_bank3_accname: null,
        wit_bank3_username: null,
        wit_bank3_password: null,
        wit_bank3_sms: null,
        linetoken_dip: null,
        linetoken_wit: null,
        linetoken_captcha: null,
      },
      selected: { title: '' },
      option: [{ title: 'Square' }],
      options: {
        customDelimiter: {
          delimiters: ['-', '-', '-'],
          blocks: [3, 3, 4],
          uppercase: true,
        },
      },
    }
  },
  mounted() {
    this.Get_bank()
    if (this.AgID === 'ADD') {
      this.getLotto()
    } else {
      this.getAgent()
      this.getLottoEdit()
    }
  },
  methods: {
    Get_bank() {
      this.$http
        .get('https://api.rmtlotto.com/api/bank/list')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    getAgent() {
      this.$http
        .get('https://api.rmtlotto.com/api/agent/show')
        .then(response => {
          this.AgentData = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    getLotto() {
      this.$http
        .get('https://api.rmtlotto.com/api/lottotypemange')
        .then(response => {
          this.items = []
          response.data.forEach(element => {
            if (element.subtype === 1 && element.Subhead.length > 0) {
              element.Subhead.forEach(sub => {
                // eslint-disable-next-line no-param-reassign
                sub.ag_status = 0
              })
            }
            this.items.push({ ag_status: 0, ...element })
          })
        })
        .catch(error => console.log(error))
    },
    getLottoEdit() {
      this.$http
        .get(`https://api.rmtlotto.com/api/lottotypemange/${this.AgID}`)
        .then(response => {
          this.items = response.data
          const St = JSON.stringify(response.data)
          this.DefaultItem = JSON.parse(St)
        })
        .catch(error => console.log(error))
    },
    submit() {
      if (this.AgID === 'ADD') {
        const formData = {
          Agent: this.AgentData,
          Lotto: this.items,
        }
        this.$http
          .post('https://api.rmtlotto.com/api/agent/store', formData)
          .then(() => {
            this.getAgent()
            this.Success('เพิ่มข้อมูล สำเร็จ')
            this.$router.push({ name: 'agent' })
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        const Diff = this.CheckDiff()
        const formData = {
          Agent: this.AgentData,
          Lotto: Diff,
        }
        this.$http
          .post(
            `https://api.rmtlotto.com/api/agent/updateByAdmin/${this.AgID}`,
            formData,
          )
          .then(() => {
            this.getAgent()
            this.Success('แก้ไขข้อมูล สำเร็จ')
            this.$router.push({ name: 'agent' })
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    CheckDiff() {
      const DiffArr = []
      const DiffSubArr = []
      // eslint-disable-next-line
      for (const K in this.items) {
        // eslint-disable-next-line
        for (const I in this.items[K]) {
          if (I === 'Subhead') {
            // eslint-disable-next-line
            for (const KeySub in this.items[K][I]) {
              // eslint-disable-next-line
              for (const KeyInSub in this.items[K][I][KeySub]) {
                if (
                  this.items[K][I][KeySub][KeyInSub]
                  !== this.DefaultItem[K][I][KeySub][KeyInSub]
                ) {
                  DiffSubArr.push(this.items[K][I][KeySub])
                  break
                }
              }
            }
          } else if (
            // eslint-disable-next-line eqeqeq
            this.items[K][I] != this.DefaultItem[K][I]
            && I !== '_showDetails'
          ) {
            DiffArr.push(this.items[K])
            break
          }
        }
      }
      return { DiffArr, DiffSubArr }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
i {
  color: #2e5d92;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@mixin can-toggle-branding(
  $can-toggle-off-color: #2e5d92,
  $can-toggle-on-color: #777,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type='checkbox'] {
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after {
          color: darken($can-toggle-off-color, 10%);
        }
      }
    }
    &:hover ~ label {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ label {
        &:hover {
          color: darken($can-toggle-on-color, 3%);
        }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after {
            color: darken($can-toggle-on-color, 5%);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after {
              color: darken($can-toggle-on-color, 10%);
            }
          }
        }
      }
    }
  }

  label {
    .can-toggle__label-text {
      flex: 1;
    }

    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type='checkbox'] {
    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d(
              $can-toggle-width -
                ($can-toggle-switch-width + $can-toggle-offset),
              0,
              0
            );
          }
        }
      }
      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            &:after {
              box-shadow: $can-toggle-shadow;
            }
          }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }
  }
}

.can-toggle {
  position: relative;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  //overflow: hidden;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  // Create toggles of different sizes by overriding the can-toggle-appearance mixin defaults
  &.can-toggle--size-small {
    @include can-toggle-appearance(
      90px,
      // Toggle width
        28px,
      // Toggle height
        2px,
      // Toggle border radius
        1px,
      // Offset (distance btw switch and box)
        13px,
      // Label font size
        10px,
      // Switch font size
        0 2px 2px rgba(black, 0.4)
    );
  }

  &.can-toggle--size-large {
    @include can-toggle-appearance(
      160px,
      // Toggle width
        50px,
      // Toggle height
        4px,
      // Toggle border radius
        2px,
      // Offset (distance btw switch and box)
        14px,
      // Label font size
        14px,
      // Switch font size
        0 4px 4px rgba(black, 0.4)
    );
  }

  // Or re-brand the toggle for different applications
  &.demo-rebrand-1 {
    @include can-toggle-branding(
      #b53e74,
      // Off state color
        #3d9c72,
      // On state color
        rgba(white, 0.6),
      // Off state text color ease-in-out / / Transition timing function
    );
  }

  &.demo-rebrand-2 {
    @include can-toggle-branding(
      #444,
      // Off state color
        #69be28,
      // On state color
        rgba(white, 0.7),
      // Off state text color cubic-bezier(0.86, 0, 0.07, 1) / / Transition timing
        function
    );
    @include can-toggle-appearance(
      120px,
      // Toggle width
        60px,
      // Toggle height
        60px,
      // Toggle border radius
        2px,
      // Offset (distance btw switch and box)
        13px,
      // Label font size
        13px,
      // Switch font size
        0 4px 4px rgba(black, 0.4)
    );
  }
}
</style>
